import { PolicySummary } from "raci-change-my-payment-clientproxy";
import { PolicyNumberPrefix } from "raci-react-library";
import { PaymentFrequency } from "../../constants";

export const policyIsAnnualOrSemiAnnual = (policy: PolicySummary): boolean => {
  return policy.paymentFrequency === PaymentFrequency.Annual || policy.paymentFrequency === PaymentFrequency.SemiAnnual;
};

export const getGtmPolicyTypeFromNumber = (policyNumber: string): string => {
  const prefix = policyNumber.substring(0, 3) as PolicyNumberPrefix;
  switch (prefix) {
    case PolicyNumberPrefix.Boat:
      return "Boat";
    case PolicyNumberPrefix.CaravanTrailer:
      return "Caravan";
    case PolicyNumberPrefix.ElectricMobility:
      return "Electric Mobility";
    case PolicyNumberPrefix.Home:
      return "Home";
    case PolicyNumberPrefix.Motor:
      return "Motor";
    case PolicyNumberPrefix.Motorcycle:
      return "Motorcycle";
    case PolicyNumberPrefix.Pet:
      return "Pet";
    default:
      return "";
  }
};

// TODO SPK-7397: Handle caravan policies after CEO go live
export const canRenewPolicyViaSpark = ({ policyNumber }: { policyNumber: string }): boolean => {
  return policyNumber.startsWith(PolicyNumberPrefix.Motor);
};
