import { RacwaBreadcrumbDetails } from "@racwa/react-components";
import { PolicyNumberPrefix, productTypeFormatter } from "raci-react-library";
import { useMemo } from "react";
import { HOW_YOU_PAY_SIDEBAR_PROGRESS } from "../../constants";
import usePolicyNumber from "../../hooks/usePolicyNumber";
import { getB2CPCMUrl } from "../../utils/getB2CPCMUrl";

export const useBreadcrumbs = (): RacwaBreadcrumbDetails | undefined => {
  const policyNumber = usePolicyNumber();

  const getPolicyNumberBreadcrumb = (policyNumber: string) => {
    try {
      const policyType = productTypeFormatter(policyNumber.substring(0, 3) as PolicyNumberPrefix);
      return `${policyType} insurance ${policyNumber}`;
    } catch (ex) {
      return policyNumber;
    }
  };

  return useMemo(() => {
    const breadcrumbDetails: RacwaBreadcrumbDetails = {
      links: [
        {
          key: "breadcrumbMyRac",
          href: `${process.env.REACT_APP_MY_RAC_LOGIN_PAGE}`,
          name: "myRAC",
        },
        {
          key: "breadcrumbPolicyNumber",
          href: getB2CPCMUrl(policyNumber),
          name: getPolicyNumberBreadcrumb(policyNumber),
        },
      ],
      currentPage: {
        name: HOW_YOU_PAY_SIDEBAR_PROGRESS,
        key: "breadcrumbHowYouPay",
      },
    };

    return policyNumber ? breadcrumbDetails : undefined;
  }, [policyNumber]);
};

export default useBreadcrumbs;
