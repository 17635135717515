import { isEmptyObject, useHasSession } from "raci-react-library";
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ROUTE_PRE_FORM_PAGE_URL } from "../../../views/routes.config";
import { CONTEXT_FLOW_STATE_SESSION_KEY } from "../../constants";
import { DEFAULT_FLOW_STATE, FlowState } from "./types";

export const FlowStateContext = createContext<[FlowState, Dispatch<SetStateAction<FlowState>>] | undefined>(undefined);

export const FlowStateProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const hasSession = useHasSession();
  const [flowState, setFlowState] = useState(() => {
    const storedFlowState = JSON.parse(sessionStorage.getItem(CONTEXT_FLOW_STATE_SESSION_KEY) ?? "{}") as FlowState;
    const isStoredFlowStateValid = !!storedFlowState && !isEmptyObject(storedFlowState);
    const useStoredFlowState = isStoredFlowStateValid && location.pathname.toLowerCase() !== ROUTE_PRE_FORM_PAGE_URL;
    return useStoredFlowState ? storedFlowState : DEFAULT_FLOW_STATE;
  });

  useEffect(() => {
    if (hasSession) {
      sessionStorage.setItem(CONTEXT_FLOW_STATE_SESSION_KEY, JSON.stringify(flowState));
    }
  }, [hasSession, flowState]);
  return <FlowStateContext.Provider value={[flowState, setFlowState]}>{children}</FlowStateContext.Provider>;
};

export default FlowStateProvider;
