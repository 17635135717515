import { useSessionTimeRemaining } from "raci-react-library";
import useApiClient from "../../useApiClient";
import useHasValidSession from "../useHasValidSession";

export const useOnResetExpiry = () => {
  const client = useApiClient();
  const hasValidSession = useHasValidSession();
  const { setTimeoutTime } = useSessionTimeRemaining();

  const resetSessionExpiry = async () => {
    if (hasValidSession) {
      const response = await client.resetSessionExpiry();

      const expiryInSeconds = response.result;
      const expiryInMs = expiryInSeconds * 1000;
      setTimeoutTime(expiryInSeconds > 0 ? new Date().getTime() + expiryInMs : null);
    } else {
      setTimeoutTime(null);
    }
  };

  return resetSessionExpiry;
};

export default useOnResetExpiry;
