import { LinkProps } from "@mui/material";
import { FooterProps } from "@racwa/react-components";
import { EMPTY_URL } from "raci-react-library";

export const getFooterProps = (variant?: "simple" | "condensed" | "sidebar") => {
  const getLinkProps = (link?: string): Partial<LinkProps> => ({
    href: link ?? EMPTY_URL,
  });

  const footerProps: FooterProps = {
    variant,
    privacyLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE),
    securityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_SECURITY_PAGE),
    disclaimerLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_DISCLAIMER_PAGE),
    accessibilityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_ACCESSIBILITY_PAGE),
  };

  if (variant) {
    footerProps.variant = variant;
  }

  return footerProps;
};
