import { HTTP_STATUS_CODE_OK, LoadingStatus, useClearSession, useSessionIdStorageKey } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { ROUTE_PRE_FORM_PAGE_URL, ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL } from "../../views/routes.config";
import { sessionIdAtom } from "../atoms";
import { CONTEXT_POLICY_NUMBER_SESSION_KEY } from "../constants";
import useApiClient from "../useApiClient";

/**
 * Hook to create a session.
 *
 * The hook is set as a prop in SessionStatusProvider.
 *
 * The hook should really only be called once on entry
 * to the app on the preform/splash page.
 *
 * Avoid using SessionId in sessionStorage as a requirement
 * to call `createSession` as this can create problems with
 * new sessions that are that are generated in same tab as
 * a previous session that has not yet expired.
 *
 * The dependencies for the useEffect can result in issues
 * when user comes in to new session in the same tab as a
 * previous session, see SPK-7036 for MAP for more details.
 */
export const useCreateSession = () => {
  const client = useApiClient();
  const location = useLocation();
  const navigate = useNavigate();
  const clearSession = useClearSession();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const setSessionId = useSetRecoilState(sessionIdAtom);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        // Hook will only be called once on preform page, so start
        // a fresh session by clearing out any saved session state.
        clearSession(CONTEXT_POLICY_NUMBER_SESSION_KEY);

        const response = await client.createSession();

        const sessionId = response.result;
        const isSessionIdValid = !!sessionId.trim();

        if (!isSessionIdValid || response.status !== HTTP_STATUS_CODE_OK) {
          setIsError(true);
        } else {
          sessionStorage.setItem(sessionIdStorageKey, sessionId);
          setSessionId(sessionId);
          setIsSuccess(true);
        }
      } catch {
        setIsSuccess(false);
        setIsError(true);

        // Replace browser history user as should not be able to navigate back to the loading/splash screen
        navigate(ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL, { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    if (location.pathname === ROUTE_PRE_FORM_PAGE_URL) {
      !isSuccess && !isLoading && !isError && !isCancelled && createSession();
    } else if (!isSuccess) {
      setIsSuccess(true);
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]); // SPK-7313 - Be careful when adding deps as it can cause issues with session creation

  return isSuccess ? LoadingStatus.Successful : isError ? LoadingStatus.Error : LoadingStatus.Loading;
};

export default useCreateSession;
