import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import SidebarContent from "../../SidebarContent";
import useRoutes from "../../useRoutes";
import usePage from "../usePage";

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const commonPageProps = usePage();
  const { steps, activeStepIndex, canNavigateToPreviousStep, navigateToPreviousStep } = useRoutes();

  const sidebarTitle = process.env.REACT_APP_NAME ?? "";

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: () => navigateToPreviousStep && navigateToPreviousStep(),
  };

  return {
    sidebarTitle,
    sidebarContent: <SidebarContent />,
    steps,
    activeStepIndex,
    mobileStepperProps,
    ...commonPageProps,
  };
};

export default useStepperPage;
