import { gtm, phoneCall } from "@racwa/analytics";
import { RacwaResponsiveHeaderProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, InsurancePhoneNumber, PhoneButton } from "raci-react-library";
import { useRecoilValue } from "recoil";
import { gtmPolicyTypeAtom } from "../../atoms";
import { getFooterProps } from "../../Layout/getFooterProps";
import useBreadcrumbs from "../../Navigation/useBreadcrumbs";
import { createHowYouPayPageViewUrl } from "../../utils/analytics";

type CommonPageProps = Pick<
  RacwaStepperTemplateProps,
  | "breadcrumbs"
  | "responsiveHeaderProps"
  | "headerEndAction"
  | "notificationBannerContent"
  | "showNotificationBanner"
  | "footerProps"
>;

export const usePage = (): CommonPageProps => {
  const breadcrumbs = useBreadcrumbs();
  const policyType = useRecoilValue(gtmPolicyTypeAtom);
  const footerProps = getFooterProps("sidebar");

  const responsiveHeaderProps: RacwaResponsiveHeaderProps = {
    NavBreadcrumbsProps: {
      homeLink: process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL,
    },
  };

  const phoneButton = (
    <PhoneButton
      id="header-phone-button"
      phone={InsurancePhoneNumber}
      onClick={() =>
        gtm(phoneCall(InsurancePhoneNumber.displayWithoutSpaces, { url: createHowYouPayPageViewUrl(policyType) }))
      }
    />
  );

  let headerEndAction = phoneButton;
  let notificationBannerContent;

  /*
   ** WARNING:
   ** KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO TO THE BELOW IF STATEMENT
   ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET ENVIRONMENT
   ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE AND SHIP CODE
   ** (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED JAVASCRIPT ARTIFACTS)
   */
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { TestBanner, HeaderButtons } = require("raci-react-library-npe");
    const { EnvironmentHealthStatus } = require("../../../__NPE__/EnvironmentHealthStatus");

    notificationBannerContent = <TestBanner />;

    let npeButtons;

    // Environment Health Status checks fail when targeting local BFF
    if (!(process.env.REACT_APP_API_BASE_URL ?? "").includes("localhost")) {
      npeButtons = [<EnvironmentHealthStatus />];
    }

    headerEndAction = (
      <HeaderButtons
        phoneButton={phoneButton}
        npeButtons={npeButtons}
        isUat={process.env.REACT_APP_ENVIRONMENT === "UAT"}
        appConfig={{ showOverrideToNumberSelector: true }}
        menuMaxWidth={752}
      />
    );
  }

  return {
    breadcrumbs,
    responsiveHeaderProps,
    headerEndAction,
    notificationBannerContent,
    showNotificationBanner: !!notificationBannerContent,
    footerProps,
  };
};

export default usePage;
