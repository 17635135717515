import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaThemeProvider } from "@racwa/react-components";
import {
  Backdrop,
  SessionStatePrefixProvider,
  SessionStatusProvider,
  SessionTimeoutDialog,
  WebChat,
} from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import FlowStateProvider from "./shared/components/FlowStateProvider";
import PolicyNumberProvider from "./shared/components/PolicyNumberProvider";
import { SESSION_KEY_PREFIX } from "./shared/constants";
import useGetWebChatInformation from "./shared/hooks/useGetWebChatInformation";
import useOnExpiry from "./shared/hooks/useOnExpiry";
import useOnResetExpiry from "./shared/hooks/useOnResetExpiry";
import useCreateSession from "./shared/useCreateSession";
import { reactPlugin } from "./shared/utils/applicationInsights";
import Views from "./views";
import {
  ROUTE_CONFIRMATION_URL,
  ROUTE_PRE_FORM_PAGE_URL,
  ROUTE_UTILITY_CALL_US_URL,
  ROUTE_UTILITY_ROADBLOCK_PAGE_URL,
  ROUTE_UTILITY_SESSION_TIMEOUT_URL,
  ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL,
} from "./views/routes.config";

export const App: React.FC = () => (
  <RacwaThemeProvider>
    <RecoilRoot>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Backdrop />
        <BrowserRouter>
          <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
            <SessionStatusProvider useCreateSession={useCreateSession}>
              <FlowStateProvider>
                <PolicyNumberProvider>
                  <Views />
                  <SessionTimeoutDialog
                    warnAtMinute={5}
                    useOnResetExpiry={useOnResetExpiry}
                    useOnExpiry={useOnExpiry}
                    disallowPages={[
                      ROUTE_PRE_FORM_PAGE_URL,
                      ROUTE_UTILITY_CALL_US_URL,
                      ROUTE_UTILITY_ROADBLOCK_PAGE_URL,
                      ROUTE_UTILITY_SESSION_TIMEOUT_URL,
                      ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL,
                      ROUTE_CONFIRMATION_URL,
                    ]}
                  />
                  <WebChat useGetWebChatInformation={useGetWebChatInformation} />
                </PolicyNumberProvider>
              </FlowStateProvider>
            </SessionStatusProvider>
          </SessionStatePrefixProvider>
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </RecoilRoot>
  </RacwaThemeProvider>
);

export default App;
