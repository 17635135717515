import { RacwaStandardPageTemplateProps } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { useLocation } from "react-router-dom";
import { UtilityPageHeadings, UtilityPageNames, utilityRoutes } from "../../../views/routes.config";
import { canRenewPolicyViaSpark } from "../../utils/policyUtils";
import usePage from "../usePage";
import usePolicyNumber from "../usePolicyNumber";

export const useStandardPage = (): RacwaStandardPageTemplateProps => {
  const commonPageProps = usePage();
  const location = useLocation();
  const policyNumber = usePolicyNumber();

  const currentUtilityRoute = utilityRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname.toLowerCase(),
  );

  let heading = currentUtilityRoute?.heading ?? UtilityPageHeadings.Default;

  if (currentUtilityRoute?.name === UtilityPageNames.RenewalTime) {
    heading = canRenewPolicyViaSpark({ policyNumber }) ? UtilityPageHeadings.RenewalTime : UtilityPageHeadings.Default;
  }

  return {
    heading,
    bannerTextColour: colors.white,
    navBreadcrumbProps: commonPageProps.responsiveHeaderProps?.NavBreadcrumbsProps,
    ...commonPageProps,
  };
};

export default useStandardPage;
