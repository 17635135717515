import { lazy } from "react";
import { HOW_YOU_PAY_SIDEBAR_PROGRESS } from "../shared/constants";

const CallUs = lazy(() => import("./CallUs"));
const RenewalTime = lazy(() => import("./RenewalTime"));
const Roadblock = lazy(() => import("./Roadblock"));
const SystemUnavailable = lazy(() => import("./SystemUnavailable"));
const SessionTimeout = lazy(() => import("./SessionTimeout"));

const PreForm = lazy(() => import("../views/PreForm"));
const HowYouPay = lazy(() => import("./HowYouPay"));
const Confirmation = lazy(() => import("./Confirmation"));

export enum PreFormRoute {
  PreForm = "pre-form",
}

export enum HowYouPayRoutes {
  HowYouPay = "review",
  Confirmation = "confirmation",
}

export enum UtilityRoutes {
  CallUs = "call-us",
  RenewalTime = "renewal-time",
  Roadblock = "roadblock",
  SessionTimeout = "408",
  SystemUnavailable = "500",
}

export enum PreFormPageNames {
  PreForm = "",
}

export enum HowYouPayPageNames {
  HowYouPay = "Payment details",
  Confirmation = "Confirmation",
}

export enum UtilityPageNames {
  CallUs = "Call us",
  Roadblock = "Roadblock",
  RenewalTime = "Renewal time",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
}

export enum UtilityPageHeadings {
  Default = "Uh oh!",
  CallUs = "Uh oh!",
  RenewalTime = "It's renewal time",
  Roadblock = "Uh oh!",
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
}

export const ROUTE_PRE_FORM_PAGE_URL = `${process.env.REACT_APP_HOMEPAGE}`;
export const ROUTE_HOW_YOU_PAY_URL = `${process.env.REACT_APP_HOMEPAGE}/${HowYouPayRoutes.HowYouPay}`;
export const ROUTE_CONFIRMATION_URL = `${process.env.REACT_APP_HOMEPAGE}/${HowYouPayRoutes.Confirmation}`;
export const ROUTE_UTILITY_CALL_US_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.CallUs}`;
export const ROUTE_UTILITY_RENEWAL_TIME_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.RenewalTime}`;
export const ROUTE_UTILITY_ROADBLOCK_PAGE_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.Roadblock}`;
export const ROUTE_UTILITY_SESSION_TIMEOUT_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.SessionTimeout}`;
export const ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL = `${process.env.REACT_APP_HOMEPAGE}/${UtilityRoutes.SystemUnavailable}`;

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const preFormRoutes: RouteInformation[] = [
  {
    key: PreFormRoute.PreForm,
    path: ROUTE_PRE_FORM_PAGE_URL,
    name: PreFormPageNames.PreForm,
    element: <PreForm />,
  },
];

export const howYouPayRoutes: RouteInformation[] = [
  {
    key: HowYouPayRoutes.HowYouPay,
    path: ROUTE_HOW_YOU_PAY_URL,
    name: HOW_YOU_PAY_SIDEBAR_PROGRESS,
    element: <HowYouPay />,
  },
  {
    key: HowYouPayRoutes.Confirmation,
    path: ROUTE_CONFIRMATION_URL,
    name: HowYouPayPageNames.Confirmation,
    element: <Confirmation />,
  },
];

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.CallUs,
    path: ROUTE_UTILITY_CALL_US_URL,
    name: UtilityPageNames.CallUs,
    element: <CallUs />,
  },
  {
    key: UtilityRoutes.RenewalTime,
    path: ROUTE_UTILITY_RENEWAL_TIME_URL,
    name: UtilityPageNames.RenewalTime,
    element: <RenewalTime />,
  },
  {
    key: UtilityRoutes.Roadblock,
    path: ROUTE_UTILITY_ROADBLOCK_PAGE_URL,
    name: UtilityPageNames.Roadblock,
    element: <Roadblock />,
  },
  {
    key: UtilityRoutes.SessionTimeout,
    path: ROUTE_UTILITY_SESSION_TIMEOUT_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
  },
];

export const allRoutes = [...howYouPayRoutes, ...utilityRoutes];

/**
 * Disable RouteGuard checks for all utility pages and for all confirmation pages.
 * Once confirmation page has been reached, user should not be able to navigate back from confirmation.
 */
export const disableRouteGuardChecks = utilityRoutes
  .map((item) => item.path)
  .concat(ROUTE_PRE_FORM_PAGE_URL)
  .concat(ROUTE_CONFIRMATION_URL);
