import { AtomEffect, DefaultValue, atom } from "recoil";
import {
  SESSION_KEY_ATOM_GTM_POLICY_TYPE,
  SESSION_KEY_ATOM_SESSION_ID,
  SESSION_KEY_ATOM_SHOW_WESTPAC_RETRY_DIALOG,
} from "./constants";

/** https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence */
const sessionStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const sessionIdAtom = atom<string>({
  key: SESSION_KEY_ATOM_SESSION_ID,
  default: "",
  effects: [sessionStorageEffect<string>(SESSION_KEY_ATOM_SESSION_ID)],
});

export const gtmPolicyTypeAtom = atom<string>({
  key: SESSION_KEY_ATOM_GTM_POLICY_TYPE,
  default: "",
  effects: [sessionStorageEffect<string>(SESSION_KEY_ATOM_GTM_POLICY_TYPE)],
});

export const westpacRetryDialogAtom = atom<boolean>({
  key: SESSION_KEY_ATOM_SHOW_WESTPAC_RETRY_DIALOG,
  default: false,
});
