export interface FlowState {
  /**
   * Has the members session erred.
   *
   * SPK-7313 - Enforce error state for session
   * Member will be blocked from navigating
   * back from an error page and keep trying.
   * RouteGuard will redirect the member to
   * SystemUnavailable page if hasError is true and
   * current location is not one of the error pages.
   */
  hasError: boolean;
  /** Has the members session timed out */
  hasTimedOut: boolean;
  /** Has the member completed the flow */
  isCompleted: boolean;
}

export const DEFAULT_FLOW_STATE: FlowState = {
  hasError: false,
  hasTimedOut: false,
  isCompleted: false,
};
