import { event, gtm } from "@racwa/analytics";
import { EMPTY_URL, FeedbackButton, ProductDisclosureStatementLink } from "raci-react-library";
import { useRecoilValue } from "recoil";
import { gtmPolicyTypeAtom } from "../atoms";
import { createHowYouPayPageViewUrl } from "../utils/analytics";

export const SidebarContent: React.FC = () => {
  const gtmPolicyType = useRecoilValue(gtmPolicyTypeAtom);

  return (
    <>
      <ProductDisclosureStatementLink
        url={process.env.REACT_APP_PDS_PED_URL || EMPTY_URL}
        preamble="View"
        showInline={false}
        showAbbreviation={false}
      />
      <FeedbackButton
        url={process.env.REACT_APP_FEEDBACK_URL || EMPTY_URL}
        idPrefix="drawer"
        gtmOnClickEvent={() => gtm(event("Feedback", { url: createHowYouPayPageViewUrl(gtmPolicyType) }))}
      />
    </>
  );
};

export default SidebarContent;
