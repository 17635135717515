import { useGetPreviousPageSessionState } from "raci-react-library";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { howYouPayRoutes, RouteInformation } from "../../views/routes.config";

export interface StepInformation {
  name: string;
  path: string;
}

export interface UseRoutesResults {
  isHowYouPayFlow: boolean;
  steps: StepInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
  navigateToPreviousStep?: () => void;
  canNavigateToPreviousStep: boolean;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();
  const { path: previousPageUrl } = useGetPreviousPageSessionState();

  const isHowYouPayFlow = howYouPayRoutes.filter((item) => item.path === location.pathname).length > 0;

  const steps: StepInformation[] = isHowYouPayFlow ? howYouPayRoutes.map(({ name, path }) => ({ name, path })) : [];

  const totalStepCount = isHowYouPayFlow ? steps.length : undefined;

  const activeStepIndex = isHowYouPayFlow ? steps.findIndex((item) => item.path === location.pathname) : undefined;

  const navigateToPreviousStep = useMemo(() => {
    return isHowYouPayFlow && previousPageUrl && (activeStepIndex ?? 0) > 0
      ? () => navigate(previousPageUrl, { replace: true })
      : undefined;
  }, [navigate, isHowYouPayFlow, activeStepIndex, previousPageUrl]);

  const canNavigateToPreviousStep =
    !!navigateToPreviousStep &&
    steps.indexOf(howYouPayRoutes[howYouPayRoutes.length - 1]) !== activeStepIndex &&
    activeStepIndex !== 0;

  return {
    isHowYouPayFlow,
    steps,
    formRoutes: howYouPayRoutes,
    activeStepIndex,
    totalStepCount,
    navigateToPreviousStep,
    canNavigateToPreviousStep,
  };
};

export default useRoutes;
