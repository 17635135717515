import { OpenBackdrop } from "raci-react-library";
import React, { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import useStandardPage from "../shared/hooks/useStandardPage";
import useStepperPage from "../shared/hooks/useStepperPage";
import { RouteGuard } from "../shared/RouteGuard";
import { TitleGuard } from "../shared/TitleGuard";
import useRoutes from "../shared/useRoutes";
import useSessionExpiry from "../shared/useSessionExpiry";
import {
  disableRouteGuardChecks,
  preFormRoutes,
  ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL,
  utilityRoutes,
} from "./routes.config";

const RacwaStepperPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStepperTemplate })),
);
const RacwaStandardPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStandardPageTemplate })),
);

export const Views: React.FC = () => {
  useSessionExpiry();
  const { formRoutes } = useRoutes();
  const stepperPageProps = useStepperPage();
  const standardPageProps = useStandardPage();

  return (
    <>
      <TitleGuard />
      <RouteGuard disableChecks={disableRouteGuardChecks} />
      <Routes>
        {preFormRoutes.map(({ key, path, element }) => (
          <Route key={key} path={path} element={element} />
        ))}

        <Route
          path={process.env.REACT_APP_HOMEPAGE}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStepperPageTemplate {...stepperPageProps}>
                <Outlet />
              </RacwaStepperPageTemplate>
            </Suspense>
          }
        >
          {formRoutes.map(({ key, path, element }) => (
            <Route key={key} path={path} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>

        <Route
          path={process.env.REACT_APP_HOMEPAGE}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStandardPageTemplate {...standardPageProps}>
                <Outlet />
              </RacwaStandardPageTemplate>
            </Suspense>
          }
        >
          {utilityRoutes.map(({ key, element }) => (
            <Route key={key} path={key} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}

          <Route path="*" element={<Navigate to={ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL} replace />} />
        </Route>

        <Route path="*" element={<Navigate to={ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL} replace />} />
        <Route path="/" element={<Navigate to={ROUTE_UTILITY_SYSTEM_UNAVAILABLE_URL} replace />} />
      </Routes>
    </>
  );
};

export default Views;
