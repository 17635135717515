import { event, fieldTouched, gtm, virtualPageView } from "@racwa/analytics";

export const sendHowYouPayPageView = (policyType?: string) => {
  if (policyType) {
    gtm(
      virtualPageView({
        url: createHowYouPayPageViewUrl(policyTypeForUrl(policyType)),
        title: `Policy Update - ${policyType} - Update how you pay`,
      }),
    );
  }
};

export const sendConfirmationPageView = (policyType?: string) => {
  if (policyType) {
    gtm(
      virtualPageView({
        url: createConfirmationPageViewUrl(policyTypeForUrl(policyType)),
        title: `Policy Update - ${policyType} - Update how you pay confirmation`,
      }),
    );
  }
};

export const sendRenewalTimePageView = (policyType?: string) => {
  if (policyType) {
    gtm(
      virtualPageView({
        url: createRenewalTimePageViewUrl(policyTypeForUrl(policyType)),
        title: `Policy Update - ${policyType} - Update how you pay - Its renewal time`,
      }),
    );
  }
};

export const sendSorryYouCantDoThisOnlinePageView = (policyType?: string) => {
  if (policyType) {
    gtm(
      virtualPageView({
        url: createSorryYouCantDoThisOnlinePageViewUrl(policyTypeForUrl(policyType)),
        title: `Policy Update - ${policyType} - Update how you pay - Sorry you cant do this online right now`,
      }),
    );
  }
};

export const sendErrorPageView = () => {
  gtm(virtualPageView({ url: "policy-update/update-how-you-pay/500", title: "System unavailable" }));
};

export const sendTimeoutPageView = (previousUrl?: string) => {
  gtm(
    virtualPageView({
      url: "policy-update/update-how-you-pay/408",
      title: `Session timeout - previous page: ${previousUrl}`,
    }),
  );
};

export const sendGtmExpiredCard = (policyType: string) => {
  gtm(event("Card expired", { url: createHowYouPayPageViewUrl(policyType) }));
};

export const sendGtmNewAccountOrCard = (policyType: string) => {
  gtm(event("Add a new account or card", { url: createHowYouPayPageViewUrl(policyType) }));
};

export const sendGtmSelectExistingAccount = (policyType: string) => {
  gtm(event("Existing bank or credit card details", { url: createHowYouPayPageViewUrl(policyType) }));
};

export const sendGtmReviewAndRenew = (policyType: string) => {
  gtm(event("Review and renew", { url: createRenewalTimePageViewUrl(policyType) }));
};

export const sendGtmRenewalTimeCallUs = (policyType: string) => {
  gtm(event("13 17 03", { url: createRenewalTimePageViewUrl(policyType) }));
};

export const sendGtmBackToMyRac = (policyType: string) => {
  gtm(event("Back to myRAC", { url: createSorryYouCantDoThisOnlinePageViewUrl(policyType) }));
};

export const sendGtmCantDoThisOnlineCallUs = (policyType: string) => {
  gtm(event("13 17 03", { url: createSorryYouCantDoThisOnlinePageViewUrl(policyType) }));
};

export const sendGtmFieldTouched = (field: string, policyType: string) => {
  gtm(fieldTouched(field, { url: createHowYouPayPageViewUrl(policyType) }));
};

export const createHowYouPayPageViewUrl = (policyType: string) => {
  return `policy-update/${policyTypeForUrl(policyType)}/update-how-you-pay`;
};

const createConfirmationPageViewUrl = (policyType: string) => {
  return `policy-update/${policyTypeForUrl(policyType)}/update-how-you-pay-confirmation`;
};

export const createRenewalTimePageViewUrl = (policyType: string) => {
  return `policy-update/${policyTypeForUrl(policyType)}/update-how-you-pay/its-renewal-time`;
};

export const createSorryYouCantDoThisOnlinePageViewUrl = (policyType: string) => {
  return `policy-update/${policyTypeForUrl(policyType)}/update-how-you-pay/sorry-you-cant-do-this-online-right-now`;
};

// Policy type in the url must be lower-case and hyphenated
const policyTypeForUrl = (policyType: string): string => {
  return policyType.toLowerCase().replaceAll(" ", "-");
};
